import {
  GET_MARKET_PLACE_CATEGORY_DROPDOWN_LIST,
  GET_MARKET_PLACE_CATEGORY_LIST,
} from "../../../constants";

const category = (
  state = { dropdown: [], data: [] },
  action
) => {
  switch (action.type) {
    case GET_MARKET_PLACE_CATEGORY_DROPDOWN_LIST:
      return {
        ...state,
        dropdown: action.payload.data,
      };
    case GET_MARKET_PLACE_CATEGORY_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default category;
