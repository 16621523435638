import { GET_CATEGORY_DROPDOWN } from "../../constants";

const giftcard = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_CATEGORY_DROPDOWN:
      return {
        ...state,
        list: action.payload.data,
      };
    default:
      return state;
  }
};

export default giftcard;
