import { GET_REWARD_LIST } from "../../constants";

const reward = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_REWARD_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default reward;
