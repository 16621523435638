import { GET_REPORTS_LIST } from "../../constants";

const reports = (state = { dropdown: [], data: [] }, action) => {
  switch (action.type) {
    case GET_REPORTS_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default reports;
