import {
  GET_TRANSACTION_LIST,
  GET_POINTS_TRANSACTION_LIST_BY_USER_ID,
  GET_YES_BANK_TRANSACTION_LIST_BY_USER_ID,
  GET_TRANSACTION_LIST_BY_USER_ID,
  RAZORPAY_ORDER_ID,
  RAZORPAY_PAYMENT_RESPONSE
} from "../../constants";

const transaction = (
  state = { data: [], byUserId: [], pointsByUserId: [], yesBankByUserId: [],razorpay_response:[], razorpay_order_id:0 },
  action
) => {
  switch (action.type) {
    case GET_TRANSACTION_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    case GET_TRANSACTION_LIST_BY_USER_ID:
      return {
        ...state,
        byUserId: action.payload.data,
      };
    case GET_POINTS_TRANSACTION_LIST_BY_USER_ID:
      return {
        ...state,
        pointsByUserId: action.payload.data,
      };
    case GET_YES_BANK_TRANSACTION_LIST_BY_USER_ID:
      return {
        ...state,
        yesBankByUserId: action.payload.data,
      };
    case RAZORPAY_ORDER_ID:
      return {
        ...state,
        razorpay_order_id: action.payload.data,
      };
    case RAZORPAY_PAYMENT_RESPONSE:
      var resp = action.payload.data
      return {
        ...state,
        razorpay_response:resp.razorpay_order_id,
        razorpay_amount: "Amount: "+resp.amount,
        message: "Message: "+resp?.error?.description,
      };
    default:
      return state;
  }
};

export default transaction;
