import { PAGINATION } from "../../constants";

const pagination = (state = { columns: [], limit: 10, page: 1, totalPages: 0, totalResults: 0 }, action) => {
  switch (action.type) {
    case PAGINATION:
      return {
        ...state,
        columns: action.payload.columns,
        limit: action.payload.limit,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
    default:
      return state;
  }
};

export default pagination;
