import { GET_SERVICE_DROPDOWN_LIST, GET_SERVICE_LIST, GET_ZAGGLE_DROPDOWN_LIST } from "../../constants";
const service = (state = { dropdown: [], data: [] }, action) => {
  switch (action.type) {
    case GET_SERVICE_DROPDOWN_LIST:
      return {
        ...state,
        dropdown: action.payload.data,
      };
    case GET_SERVICE_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
      case GET_ZAGGLE_DROPDOWN_LIST:
        return {
          ...state,
          peding_zaggle_list: action.payload.data,
        };
    default:
      return state;
  }
};

export default service;
