import { GET_MERCHANT_ID_LIST } from "../../constants";

const merchantCategoryCode = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_MERCHANT_ID_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default merchantCategoryCode;
