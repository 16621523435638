import { PROGRESS } from "../../constants";

const datatable = (state = { progress: true }, action) => {
  switch (action.type) {
    case PROGRESS:
      return {
        ...state,
        progress: action.payload.progress,
      };
    default:
      return state;
  }
};

export default datatable;
