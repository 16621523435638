import { combineReducers } from "redux";

import category from "./category";
import store from "./store";

const marketPlace = combineReducers({
  category,
  store,
});

export default marketPlace;
