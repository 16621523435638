export const HOME = "HOME";

export const ID = "ID";
export const FORM_DATA = "FORM_DATA";

export const IS_OPEN = "IS_OPEN";
export const TITLE = "TITLE";

export const DELETE_IS_OPEN = "DELETE_IS_OPEN";
export const GET_ZAGGLE_DROPDOWN_LIST = "GET_ZAGGLE_DROPDOWN_LIST";

export const DOWNLOAD_IS_OPEN = "DOWNLOAD_IS_OPEN";

export const GET_MCC_LIST = "GET_MCC_LIST";
export const GET_MCC_DROPDOWN_LIST = "GET_MCC_DROPDOWN_LIST";
export const GET_MCC_BY_ID = "GET_MCC_BY_ID";

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_DROPDOWN_LIST = "GET_CATEGORY_DROPDOWN_LIST";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";

export const GET_MARKET_PLACE_CATEGORY_LIST = "GET_MARKET_PLACE_CATEGORY_LIST";
export const GET_MARKET_PLACE_CATEGORY_DROPDOWN_LIST =
  "GET_MARKET_PLACE_CATEGORY_DROPDOWN_LIST";
export const GET_MARKET_PLACE_CATEGORY_BY_ID =
  "GET_MARKET_PLACE_CATEGORY_BY_ID";

export const GET_MARKET_PLACE_STORE_LIST = "GET_MARKET_PLACE_STORE_LIST";
export const GET_MARKET_PLACE_STORE_DROPDOWN_LIST =
  "GET_MARKET_PLACE_STORE_DROPDOWN_LIST";
export const GET_MARKET_PLACE_STORE_BY_ID = "GET_MARKET_PLACE_STORE_BY_ID";

export const GET_REWARD_LIST = "GET_REWARD_LIST";
export const GET_REWARD_BY_ID = "GET_REWARD_BY_ID";
export const GET_WALLET_EMPTY_CUSTOMER = "GET_WALLET_EMPTY_CUSTOMER";
export const IS_REGISTER_LOADER = "IS_REGISTER_LOADER";

export const GET_SERVICE_LIST = "GET_SERVICE_LIST";
export const GET_SERVICE_DROPDOWN_LIST = "GET_SERVICE_DROPDOWN_LIST";
export const GET_SERVICE_BY_ID = "GET_SERVICE_BY_ID";
export const GET_CATEGORY_DROPDOWN = "GET_CATEGORY_DROPDOWN";

export const GET_MERCHANT_ID_LIST = "GET_MERCHANT_ID_LIST";
export const GET_MERCHANT_ID_BY_ID = "GET_MERCHANT_ID_BY_ID";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";

export const GET_TRANSACTION_LIST = "GET_TRANSACTION_LIST";

export const GET_POINTS_LIST = "GET_POINTS_LIST";

export const GET_YES_BANK_CARD_LIST = "GET_YES_BANK_CARD_LIST";

export const GET_BANER_LIST = "GET_BANER_LIST";
export const GET_BANER_ID_BY_ID = "GET_BANER_ID_BY_ID";

export const GET_USER_LIST = "GET_USER_LIST";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_NSDL_BY_ID = "GET_NSDL_BY_ID";
export const GET_WALLET_BY_ID = "GET_WALLET_BY_ID";

export const PAGINATION = "PAGINATION";

export const PROGRESS = "PROGRESS";

export const GET_TRANSACTION_LIST_BY_USER_ID =
  "GET_TRANSACTION_LIST_BY_USER_ID";

export const GET_POINTS_TRANSACTION_LIST_BY_USER_ID =
  "GET_POINTS_TRANSACTION_LIST_BY_USER_ID";

export const GET_YES_BANK_TRANSACTION_LIST_BY_USER_ID =
  "GET_YES_BANK_TRANSACTION_LIST_BY_USER_ID";

export const GET_POINTS_LIST_BY_USER_ID = "GET_POINTS_LIST_BY_USER_ID";

export const FILTER = "FILTER";

export const GET_REPORTS_LIST = "GET_REPORTS_LIST";
export const GET_WALLET_LOAD_RESPONSE = "GET_WALLET_LOAD_RESPONSE";
export const RAZORPAY_ORDER_ID = "RAZORPAY_ORDER_ID";
export const RAZORPAY_PAYMENT_RESPONSE = "RAZORPAY_PAYMENT_RESPONSE";
export const GET_NSDL_WALLET_LOAD = "GET_NSDL_WALLET_LOAD";
export const GET_NSDL_BALANCE = "GET_NSDL_BALANCE";


