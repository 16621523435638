import { GET_WALLET_BY_ID, GET_USER_LIST, GET_WALLET_EMPTY_CUSTOMER, GET_WALLET_LOAD_RESPONSE, GET_WALLET_BALANCE } from "../../constants";

const user = (state = { personal_det: {}, transaction_reference_number: "Please Wait.!.", data: [], data_by_id: {},data_by_id_cust_det:{}, address:{}, empty_count: 0, GET_WALLET_LOAD_RESPONSE }, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    case GET_WALLET_BY_ID:
      // console.log(JSON.parse(action.payload.data.novopay_customer_det).address_details[0])
      return {
        ...state,
        data_by_id: action.payload.data,
        personal_det: JSON.parse(action.payload.data.novopay_customer_det).personal_details,
        data_by_id_cust_det: JSON.parse(action.payload.data.novopay_customer_det),
        address: JSON.parse(action.payload.data.novopay_customer_det).address_details[0],
        data_by_id_limit: JSON.parse(action.payload.data.novopay_limit),
      };
    case GET_WALLET_EMPTY_CUSTOMER:
        return {
          ...state,
          empty_count: action.payload.data.data,
        };
        case GET_WALLET_LOAD_RESPONSE:
          return {
            ...state,
            transaction_reference_number: action.payload.data.transaction_reference_number,
          };
    case GET_WALLET_BALANCE:
      console.log(action.payload.data)
      return {
        ...state,
        wallet_balance: action.payload.data,
      };
    default:
      return state;
  }
};

export default user;
