import React from "react";
import * as Icon from "react-feather";
const menu1  = []
const checkRole = JSON.parse(localStorage.getItem("user"))
if(checkRole && checkRole.role === 'ADMIN'){
  menu1.push({
    id: "home",
    title: "Home",
    icon: <Icon.Home size={20} />,
    navLink: "/home",
  })
}
if(checkRole && checkRole.role === 'ADMIN'){
  menu1.push({
    id: "nsdl",
    title: "Nsdl",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["admin"],
    navLink: "/nsdl",
  })
}   
// Wallet
// menu1.push({
//   id: "wallet",
//   title: "Wallet",
//   type: "item",
//   icon: <Icon.CreditCard size={20} />,
//   permissions: ["admin"],
//   navLink: "/wallet",
// })
// ZAGGLE
// menu1.push({
//   id: "zaggle",
//   title: "zaggle",
//   type: "item",
//   icon: <Icon.CreditCard size={20} />,
//   permissions: ["admin"],
//   navLink: "/zaggle",
// })
// Transactions
if(checkRole && checkRole.role === 'ADMIN'){
  menu1.push({
    id: "transaction",
    title: "Transaction",
    type: "item",
    icon: <Icon.Repeat size={20} />,
    permissions: ["admin"],
    navLink: "/transaction",
  })
}  
// staff
/*if(checkRole && checkRole.role === 'ADMIN'){
  menu1.push({
    id: "staff",
    title: "Staff",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/staff",
  })
}  */


// check settings
/*if(checkRole && checkRole.role === 'ADMIN'){
  menu1.push({
    id: "settings",
    title: "Settings",
    icon: <Icon.Settings size={20} />,
    children: [
      // {
      //   id: "upload",
      //   title: "Upload",
      //   type: "item",
      //   icon: <Icon.Loader size={20} />,
      //   permissions: ["admin"],
      //   navLink: "/upload/wallet",
      // },
      // {
      //   id: "balance",
      //   title: "Balance reload",
      //   type: "item",
      //   icon: <Icon.RefreshCcw size={20} />,
      //   permissions: ["admin"],
      //   navLink: "/balance",
      // },
      {
        id: "customer",
        title: "Customer Info",
        type: "item",
        icon: <Icon.RefreshCcw size={20} />,
        permissions: ["admin"],
        navLink: "/settings/customer",
      },
      {
        id: "pg",
        title: "PG",
        type: "item",
        icon: <Icon.Layers size={20} />,
        permissions: ["admin"],
        navLink: "/setting/pg",
      },
      
    ],
  })
}*/
export default menu1; 