import {
  GET_MCC_DROPDOWN_LIST,
  GET_MCC_LIST,
} from "../../constants";

const merchantId = (
  state = { dropdown: [], data: [] },
  action
) => {
  switch (action.type) {
    case GET_MCC_DROPDOWN_LIST:
      return {
        ...state,
        dropdown: action.payload.data,
      };
    case GET_MCC_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default merchantId;
