import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import category from "./category";
import merchantCategoryCode from "./merchant-category-code";
import modal from "./modal";
import form from "./form";
import reward from "./reward";
import marketPlace from "./market-place";
import merchantId from "./merchant-id";
import upload from "./upload";
import service from "./service";
import transaction from "./transaction";
import points from "./points";
import card from "./card";
import user from "./user";
import pagination from "./pagination";
import datatable from "./datatable";
import home from "./home";
import reports from "./category";
import baner from "./baner-type";
import giftcard from "./gift-card";
// import zaggle from "./zaggle";
import nsdl from "./nsdl";

const rootReducer = combineReducers({
  home: home,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  modal: modal,
  form: form,
  datatable: datatable,
  pagination: pagination,
  category: category,
  merchantCategoryCode: merchantCategoryCode,
  merchantId: merchantId,
  reward: reward,
  marketPlace: marketPlace,
  upload: upload,
  service: service,
  transaction: transaction,
  points: points,
  card: card,
  user: user,
  reports: reports,
  baner: baner,
  giftcard: giftcard,
  nsdl: nsdl,
});

export default rootReducer;
