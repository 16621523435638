import { HOME } from "../../constants";

const home = (
  state = {
    user_count: 0,
    kyc_user_count: 0,
    transaction_count: 0,
    ecollect_count: 0,
  },
  action
) => {
  switch (action.type) {
    case HOME:
      const data = action.payload.data;
      return {
        ...state,
        user_count: data.user_count,
        kyc_user_count: data.kyc_user_count,
        transaction_count: data.transaction_count,
        ecollect_count: data.ecollect_count,
      };
    default:
      return state;
  }
};

export default home;
