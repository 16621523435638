import { DELETE_IS_OPEN, DOWNLOAD_IS_OPEN, IS_OPEN, TITLE } from "../../constants";

const modal = (
  state = { isOpen: false, title: "", deleteIsOpen: false, downloadIsOpen: false },
  action
) => {
  switch (action.type) {
    case IS_OPEN:
      return { ...state, isOpen: action.isOpen };
    case TITLE:
      return { ...state, title: action.title };
    case DELETE_IS_OPEN:
      return { ...state, deleteIsOpen: action.isOpen };
    case DOWNLOAD_IS_OPEN:
      return { ...state, downloadIsOpen: action.isOpen };
    default:
      return state;
  }
};

export default modal;
