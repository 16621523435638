import { GET_BANER_ID_BY_ID, GET_BANER_LIST } from "../../constants";

const baner = (state = { data: [], byId: {} }, action) => {
  switch (action.type) {
    case GET_BANER_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    case GET_BANER_ID_BY_ID:
      return {
        ...state,
        byId: action.payload.data,
      };
    default:
      return state;
  }
};

export default baner;
