import { FORM_DATA, ID, FILTER } from "../../constants";

const form = (state = { id: null, data: {}, filter: {} }, action) => {
  switch (action.type) {
    case ID:
      return { ...state, id: action.id };
    case FORM_DATA:
      return { ...state, data: action.data };
    case FILTER:
      return { ...state, filter: action.filter };
    default:
      return state;
  }
};

export default form;
