import { GET_YES_BANK_CARD_LIST } from "../../constants";

const card = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_YES_BANK_CARD_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default card;
