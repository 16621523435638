import { GET_NSDL_BY_ID, GET_NSDL_WALLET_LOAD, GET_USER_LIST, IS_REGISTER_LOADER,GET_NSDL_BALANCE} from "../../constants";

const nsdl = (state = {data_by_id: {},nsdl_balance: 'Please Wait..', isLoader: false, wallet_load_response: {} }, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    case GET_NSDL_BY_ID:
      return {
        ...state,
        data_by_id: action.payload.data,
      };
     case IS_REGISTER_LOADER: 
      return {
        ...state,
        isLoader: action.payload.isloading,
     };
     case GET_NSDL_WALLET_LOAD:
       return {
        ...state,
        wallet_load_response: action.payload.data
       }
      case GET_NSDL_BALANCE: 
      return {
      ...state,
      nsdl_balance:   action?.payload?.data?.data  
      }
    default:
      return state;
  }
};

export default nsdl;