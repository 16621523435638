import { GET_POINTS_LIST, GET_POINTS_LIST_BY_USER_ID } from "../../constants";

const points = (state = { data: [], byUserId: [] }, action) => {
  switch (action.type) {
    case GET_POINTS_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    case GET_POINTS_LIST_BY_USER_ID:
      return {
        ...state,
        byUserId: action.payload.data,
      };
    default:
      return state;
  }
};

export default points;
